import React from "react";
import Todo from "./components/todoreact/todo";
// import Temp from "./components/weather/temp";
// import ChildA from "./components/practiceTab/ChildA";

const App = () => {
 
  return (
    <>
      <Todo />
      {/* <ChildA /> */}
      
    </>
  );
};

export default App;
